<template>
  <base-notecard
    :message-type="verifiedStatus.messageType"
    v-if="isAuthenticated && isEmailVerifiedRef === false"
  >
    <p>
      {{ verifiedStatus.message }}
    </p>
  </base-notecard>

  <base-notecard
    :message-type="userStatus.messageType"
    v-if="userStatus.message"
  >
    {{ userStatus.message }}
  </base-notecard>

  <main>
    <app-logo iconOnly="true" />
    <h1>Welcome to ArticSidekick</h1>
    <p>
      Artic Sidekick was created with the intention to allow speech and language
      pathologists to more accurately target articulation therapy while allowing
      for strengths and weaknesses to become more visible through objective data
      collection tools and, as a result, easily interpret goal progress.
    </p>

    <nuxt-link
      v-if="
        isAuthenticated && isEmailVerifiedRef && subscriptionStatus !== 'none'
      "
      to="/artic-sidekick"
      class="start-button"
      >Start your session</nuxt-link
    >

    <div
      class="subscriptions-container"
      v-else-if="
        isAuthenticated && isEmailVerifiedRef && subscriptionStatus == 'none'
      "
    >
      <h2>Choose your subscription</h2>
      <ul class="reset-list subscriptions">
        <li>
          <base-button
            button-style="button-primary"
            @click="sendToCheckout('basic')"
            >Basic</base-button
          >
        </li>
        <li>
          <base-button
            button-style="button-primary"
            @click="sendToCheckout('preium')"
            >Premium</base-button
          >
        </li>
      </ul>
    </div>
  </main>
</template>

<script setup lang="ts">
import { watch } from "vue";

import { loadStripe } from "@stripe/stripe-js";
import { useAuth0 } from "@auth0/auth0-vue";

import { MessageType } from "~/utils/constants";

import { User, useUserStateStore } from "@/stores/UserStateStore";
import { storeToRefs } from "pinia";

const { isAuthenticated, isLoading, user } = useAuth0();

const userSessionStateStore = useUserStateStore();
const config = useRuntimeConfig();

const stripe = await loadStripe(config.public.stripePublicKey as string);

const isEmailVerifiedRef: Ref<boolean | null> = ref(null);
// setting this to basic while there are only free accounts available
const subscriptionStatus: Ref<string> = ref("basic");

const verifiedStatus = ref({
  messageType: MessageType.WARNING,
  message: "",
});

const userStatus = ref({
  messageType: MessageType.ERROR,
  message: "",
});

function sendToCheckout(plan: string) {
  const lineItem =
    plan === "basic"
      ? config.public.articSidekickBasicPlanId
      : config.public.articSidekickPremiumPlanId;

  stripe?.redirectToCheckout({
    mode: "subscription",
    lineItems: [{ price: lineItem as string, quantity: 1 }],
    successUrl: `${window.location.origin}/?plan=${plan}`,
    cancelUrl: `${window.location.origin}/`,
  });
}

function checkSubscriptionStatus() {
  if (userSessionStateStore.userState?.subscriptionId) {
    console.log("userSessionStateStore.userState?.subscriptionId", true);
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const plan = urlParams.get("plan");

    if (plan) {
      subscriptionStatus.value = plan;
    } else {
      subscriptionStatus.value = "none";
    }
  }
}

// We will only enter the watch callback if there is a logged in user,
// and Auth0 has finished it's PKE authentication process
watch(isLoading, async () => {
  const {
    email: activeUserEmail,
    email_verified,
    given_name,
    family_name,
    picture,
  } = user.value;

  isEmailVerifiedRef.value = email_verified as boolean;

  if (isEmailVerifiedRef.value === false) {
    verifiedStatus.value = {
      messageType: MessageType.WARNING,
      message: USER_NOT_VERIFIED_MESSAGE,
    };
  }

  const { userState }: { userState: Ref<User | null> } = storeToRefs(
    userSessionStateStore
  );

  if (!userState.value) {
    const loadResponse = await userSessionStateStore.loadUser(
      activeUserEmail as string
    );

    // We did not find the current user in the database.
    if (!loadResponse) {
      try {
        // Should this complete successfully, the new user has been added to the
        // database and the user session store.
        await userSessionStateStore.createUser({
          avatar: picture,
          email: activeUserEmail,
          firstName: given_name,
          isVerified: email_verified as boolean,
          lastName: family_name,
          role: UserRole.BASIC,
        });
      } catch (error) {
        userStatus.value.message = USER_CREATE_ERROR_MESSAGE;
      }
    }
  }

  // always check whether the verified state has changed.
  const currentUser = userSessionStateStore.userState as User;
  console.dir(currentUser);

  if (currentUser.isVerified !== email_verified) {
    // The user's isVerified status has changed. We need to update the database
    // and the user session store.
    try {
      await userSessionStateStore.updateUser({
        email: activeUserEmail,
        isVerified: email_verified as boolean,
      });
    } catch (error) {
      userStatus.value.message = USER_UPDATE_ERROR_MESSAGE;
    }
  }
});
</script>

<style scoped>
main {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 60%;
  gap: var(--layout-base-spacing);
  justify-content: center;
  margin: 0 auto;
  max-width: var(--layout-max-narrow);
  text-align: center;
}

.start-button {
  background-color: var(--color-blue-30);
  border-radius: var(--border-radius-button);
  color: var(--color-blue-90);
  display: block;
  padding: var(--layout-narrow-spacing) var(--layout-base-spacing);
  text-decoration: none;
}

.start-button:hover,
.start-button:focus-visible {
  background-color: var(--color-blue-70);
  color: var(--color-neutral-10);
}

.subscriptions {
  align-items: center;
  display: flex;
  gap: var(--layout-base-spacing);
  justify-content: center;
  margin: var(--layout-base-margin) auto;
}
</style>
